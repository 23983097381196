import { Component, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { fabric } from "fabric";
import { map } from 'rxjs/operators';
import { CustomImgService } from 'src/app/_services/customImg.service';
import { FileUploadService } from 'src/app/_services/fileupload.service';
import { AppConstants } from 'src/app/common/app.constants';
import { CustomImg } from 'src/app/models/customImg';
import { ProfileUser } from 'src/app/models/profileUser';
@Component({
  templateUrl: 'customKyvong.component.html'
})

export class CustomKyvongComponent {
  subtitle: any
  profile = {
    content: '',
    postion: '',
    fullName: ''
  }
  customImg: CustomImg = {}
  name = 'Angular';
  file: any = null;
  previewUrl: any = '';
  fileToUpload: any;
  imageUrl: any;
  fullName: any
  content: any
  form: FormGroup;
  imageDownload: any
  imgHeightDownload
  imgWidthDownload
  overlayKyvong = '/assets/images/background_overlay.png';
  backgroudKyvong 
  canvas: any;
  width: any = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  height: any = (window.innerHeight > 0) ? window.innerHeight : screen.height;
  originBackend = AppConstants.API_URL2;
  slugs
  imgHeightModal
  constructor(private router: Router, private route: ActivatedRoute, private customImgService: CustomImgService, private fileUploadService: FileUploadService,
    private modalService: NgbModal) {
    this.route.params.subscribe(params => {
      if (params['slug']) {
        this.slugs = params['slug'];
      }
    });
  }

  ngOnInit() {
    this.fundBySlug(this.slugs);


  }

  fundBySlug(slug) {
    this.customImgService.findBySlug(slug).pipe(map(data => data.resultData)).subscribe(
      data => {
        this.customImg = data
        this.backgroudKyvong = this.originBackend + this.customImg.imgUrl
        console.log(this.originBackend + this.customImg.imgUrl)
        this.canvas = new fabric.Canvas('canvas-kyvong', {
          width: 2000, height: 2000
        });
        var ratio = this.canvas.getWidth() / this.canvas.getHeight();
        if ((this.width / this.height) > ratio) {
          this.width = this.height * ratio;
        } else {
          this.height = this.width / ratio - 100;
          this.width = this.width / ratio - 100;
        }
        var scale = this.width / this.canvas.getWidth();
        var zoom = this.canvas.getZoom();
        zoom *= scale;
        this.canvas.setDimensions({
          width: this.width,
          height: this.width
        });
        this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
        fabric.Image.fromURL(this.backgroudKyvong, (img) => {
          img.set({
            originX: 'left',
            originY: 'top',
            crossOrigin: 'anonymous',
          }).scale(1)
          this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas));
        },{crossOrigin: 'anonymous'});

        this.canvas.setOverlayImage(this.overlayKyvong, () => {
          this.canvas.overlayImage && this.canvas.overlayImage.scaleToWidth(this.width)
          this.canvas.renderAll()
        }, {
          // Needed to position overlayImage at 0/0
          originX: 'left',
          originY: 'top',
          crossOrigin: 'anonymous'
        });
        this.resizeCanvas()
        this.addText();
      }
    )
  }
  // getListAllProfile() {
  //   this.profileService.getAllProfile().subscribe(
  //     response => {
  //       this.profileUsers = response
  //       this.profileUsers.sort((a, b) => b.id - a.id)
  //     }, err => {

  //     }
  //   )
  // }

  resizeCanvas() {
    const outerCanvasContainer = document.getElementsByClassName("canvas-container")[0] as HTMLElement;
    if (outerCanvasContainer.clientWidth > 700) {
      outerCanvasContainer.style.width = "510";
      outerCanvasContainer.style.height = "510"
    } else {
      outerCanvasContainer.style.width = "auto";
      outerCanvasContainer.style.height = "".concat(outerCanvasContainer.clientWidth + "", "px");
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeCanvas();
  }
  loadIMGProgress(url) {
    // const file = <File>fileInput.target.files[0];
    // const imageUpload = URL.createObjectURL(file);
    // var zoom = this.canvas.getZoom();
    fabric.Image.fromURL(url, (oImg) => {
      oImg.scaleToWidth(500);
      oImg.set({
        left: 500,
        top: 300,
        cornerStyle: "circle",
        cornerColor: "red",
        cornerStrokeColor: "red",
        borderColor: "red",
        cornerSize: 15,

      });
      document.getElementById('scaleImage').addEventListener('input', value => {
        oImg.scaleX = value.target['value'] / 100;
        oImg.scaleY = value.target['value'] / 100;
        this.canvas.requestRenderAll();
      }, false);
      document.getElementById('angleImage').addEventListener('input', value => {
        const valued = parseInt(value.target['value'], 36);
        const angleParse = valued > 360 ? 360 : valued < 0 ? 0 : valued;
        this.canvas.item(0)['centeredRotation'] = true;
        oImg.set({
          angle: angleParse
        }).setCoords();
        this.canvas.renderAll();
      }, false);
      this.canvas.add(oImg);
      this.canvas.renderAll();
    });
  }
  fileProgress(fileInput: any) {
    const file = <File>fileInput.target.files[0];
    const imageUpload = URL.createObjectURL(file);
    var zoom = this.canvas.getZoom();
    fabric.Image.fromURL(imageUpload, (oImg) => {
      oImg.scaleToWidth(600);
      oImg.set({
        left: 1250,
        top: 800,
        cornerStyle: "circle",
        cornerColor: "red",
        cornerStrokeColor: "red",
        borderColor: "red",
        cornerSize: 15,

      });
      document.getElementById('scaleImage').addEventListener('input', value => {
        oImg.scaleX = value.target['value'] / 100;
        oImg.scaleY = value.target['value'] / 100;
        this.canvas.requestRenderAll();
      }, false);
      document.getElementById('angleImage').addEventListener('input', value => {
        const valued = parseInt(value.target['value'], 36);
        const angleParse = valued > 360 ? 360 : valued < 0 ? 0 : valued;
        this.canvas.item(0)['centeredRotation'] = true;
        oImg.set({
          angle: angleParse
        }).setCoords();
        this.canvas.renderAll();
      }, false);
      this.canvas.add(oImg);
      this.canvas.renderAll();
    });
  }

  addText() {

    var textbox = new fabric.Textbox(this.profile.content, {
      left: 120,
      top: 550,
      width: 1030,
      fontSize: 40,
      fontFamily: 'Oswald',
      fontWeight: 500,
      fill: '#002CB7',
      hasControls: true,
      lockMovementX: true, 
      lockMovementY: false,
      selected: true,
      selectable: true,
      moveCursor: 'cursor',
      hoverCursor: 'cursor',
      editable:false

    });

    document.getElementById('floattextArea').addEventListener('input', (event) => {
      textbox.set({
        text: event.target['value']

      })
      // if (textbox.height > 250) {
      //   textbox.fontSize = 40
      // }else if (textbox.height > 500 && textbox.height < 250) {
      //   textbox.fontSize = 65
      // }
      // console.log(textbox.height)
      // console.log(textbox.fontSize)

      if (this.profile.content.length <= 300) {
        textbox.fontSize = 65
      } else if (this.profile.content.length <= 500 && this.profile.content.length > 300) {
        textbox.fontSize = 55
      } else if (this.profile.content.length <= 600 && this.profile.content.length > 500) {
        textbox.fontSize = 50
      } else {
        textbox.fontSize = 45
      }
      // if (textbox.width < this.width) {
      //   textbox.fontSize *= textbox.width / (this.width + 1);
      //   // textbox.width = this.width;
      // }
      // if (textbox.height < this.height) {
      //   textbox.fontSize *= textbox.height / (textbox.height + 1);
      //   // textbox.height = this.height;
      // }
      this.canvas.requestRenderAll();
    }, false);
    document.getElementById('fontSize').onchange = (event) => {
      textbox.set({
        // text: event.target['value']
        fontSize: event.target['value']
      })
      console.log(event.target['value'])
      // this.canvas.getActiveObject().setFontSize(event.target['value']);
      this.canvas.requestRenderAll();
    }
    // document.getElementById('fontSize').addEventListener('input', (event) => {
    //   // textbox.set({
    //   //   text: event.target['value']
    //   // })
    //   console.log(event.target['value'])
    //   this.canvas.getActiveObject().setFontSize(event.target['value']);
    //   this.canvas.RenderAll();
    // }, false);
    var textboxHoTen = new fabric.Textbox(this.profile.fullName, {
      left: 1200,
      top: 1390,
      width: 800,
      fontSize: 35,
      fontFamily: 'Oswald',
      cornerStrokeColor: '',
      fontWeight: 700,
      fill: '#002CB7',
      type: 'text',
      textAlign: 'center',
      hasControls: true,
      lockMovementX: false, 
      lockMovementY: false,
      selected: true,
      selectable: true,
      moveCursor: 'cursor',
      hoverCursor: 'cursor',
      editable:false
    });
    document.getElementById('floatFullName').addEventListener('input', (event) => {
      textboxHoTen.set({
        text: event.target['value'],
        type: "text"
      })
      this.canvas.requestRenderAll();
      this.exist = false;
    }, false);

    var textboxChucvu = new fabric.Textbox(this.profile.postion, {
      left: 1330,
      top: 1440,
      width: 500,
      fontSize: 30, 
      fontFamily: 'Oswald',
      cornerStrokeColor: '',
      fontWeight: 700,
      fill: '#002CB7',
      textAlign: 'center',
      hasControls: true,
      lockMovementX: false, 
      lockMovementY: false,
      selected: true,
      selectable: true,
      moveCursor: 'cursor',
      hoverCursor: 'cursor',
      editable:false
    });
    document.getElementById('floatPosition').addEventListener('input', (event) => {
      textboxChucvu.set({
        text: event.target['value'],
        // type: "text"
      })
      this.exist = false;
      this.canvas.requestRenderAll();
    }, false);

    this.canvas.add(textbox);
    this.canvas.add(textboxHoTen);
    this.canvas.add(textboxChucvu)
  }

  onSubmit(): void { }

  removeObject() {
    this.canvas.getActiveObjects().forEach((obj) => {
      this.canvas.remove(obj)
    });
    this.canvas.discardActiveObject().renderAll();
  }

  openModal(exampleModalContent) {
    const windowWidth = window.screen.availWidth

    console.log(windowWidth)
    if (windowWidth >= 576) {
      this.imgWidthDownload = 466;
      this.imgHeightDownload = 466;
      this.imgHeightModal = 500
    } else {
      this.imgWidthDownload = windowWidth - 53;
      this.imgHeightDownload = windowWidth - 53;
      this.imgHeightModal = windowWidth
    }

    var multiplier = 2000 / this.width
    this.imageDownload = this.canvas.toDataURL({
      format: "png",
      quality: 1,
      multiplier: multiplier
    });
    this.modalService.open(exampleModalContent, { size: 'md' });
  }
  async downloadImage() {
    const a = document.createElement('a');
    var multiplier = 2000 / this.width
    console.log("multiplier " + multiplier)
    this.canvas.renderAll();
    a.href = this.canvas.toDataURL({
      format: "png",
      quality: 0.5,
      multiplier: multiplier
    });
    a.download = 'ky-vong-dai-hoi.png';
    document.body.appendChild(a);
    a.click();
  }

  exist: boolean = false;

  saveImageDB(update: boolean) {
    var multiplier = 2000 / this.width
    const base64 = this.canvas.toDataURL({
      format: "png",
      quality: 0.5,
      multiplier: multiplier
    });
    var block = base64.split(";");
    var contentType = block[0].split(":")[1];// In this case "image/gif"
    var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
    var blob = this.b64toBlob(realData, contentType, 0);
    const imageName = 'ky-vong-dai-hoi.png';
    const imageFile = new File([blob], imageName);
    const formData = new FormData();

    // this.profileService.addProfile(this.profile, update).subscribe(
    //   response => {
    //     if (response.error) {
    //       this.exist = true;
    //     } else {
    //       let id = response.resultData.id;
    //       formData.append('profileUserId', id);
    //       formData.append('upload', imageFile);
    //       this.fileUploadService.uploadImage(formData).subscribe(
    //         response => {
    //           // this.getListAllProfile()
    //         }, err => {
    //         }
    //       )
    //       this.modalService.dismissAll();
    //     }
    //   }, err => {
    //   }
    // )
    if (update) {
      this.downloadImage()
    }
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
