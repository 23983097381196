<div class="main-wrapper">

  <!-- partial:sidebar -->
  <app-sidebar></app-sidebar>
  <!-- partial -->

  <div class="page-wrapper">

    <!-- partial:navbar -->
    <app-navbar></app-navbar>
    <!-- partial -->

    <div class="page-content" *ngIf="href == '/daibieu-thamgia/'+hrefEnd" style="
    background: url(/assets/images/background-footer.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;    margin-right: 60px;">
      <!-- Spinner for lazyload modules -->
      <div *ngIf="isLoading" class="spinner-wrapper">
        <div class="spinner">Loading...</div>
      </div>

      <div contentAnimate *ngIf="!isLoading">
        <router-outlet></router-outlet>
      </div>

    </div>

    <div class="page-content" *ngIf="href != '/daibieu-thamgia/'+hrefEnd">

      <!-- Spinner for lazyload modules -->
      <div *ngIf="isLoading" class="spinner-wrapper">
        <div class="spinner">Loading...</div>
      </div>

      <div contentAnimate *ngIf="!isLoading">
        <router-outlet>{{href}}</router-outlet>
      </div>

    </div>

    <!-- partial:footer -->
    <app-footer></app-footer>
    <!-- partial -->

  </div>


</div>